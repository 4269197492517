import { IBetItemOptions, IBetOptions } from 'src/app/interfaces/plays/play-bet.interface';

export enum EGamesId {
	BONOLOTO = 1,
	EURODREAMS = 2,
	EUROMILLONES = 3,
	PRIMITIVA = 4,
	EL_GORDO = 5,
	LOTERIA_DE_NAVIDAD = 6,
	LOTERIA_NACIONAL = 7,
	LOTERIA_DE_EL_NIÑO = 8,
	EL_MILLON = 9,
	LLUVIA_DE_MILLONES = 10,
	QUINIELA = 11,
}

interface Option { }

class GameOptions implements Option {
	constructor(public bets: number, public maxBets: number) { }
}

class MatrixOptions implements Option {
	constructor(public first: number, public last: number, public rows: number, public cols: number, public maxItems: number, public singleBetMax: number, public orientation: string = 'down') { }
}

class QuinielaOptions implements Option { }

class LoteriaOptions implements Option { }

export class Game {
	constructor(public id: EGamesId, public name: string, public slug: string, public options?: GameOptions, public main?: Option, public extra?: Option) { }
}

const gamesMap: { [key in EGamesId]: Game } = {
	[EGamesId.BONOLOTO]: new Game(EGamesId.BONOLOTO, 'Bonononoloto', 'bonono', new GameOptions(8, 8), new MatrixOptions(1, 49, 7, 8, 11, 6, 'down')),
	[EGamesId.EURODREAMS]: new Game(EGamesId.EURODREAMS, 'Eurodreams', 'banana', new GameOptions(6, 8), new MatrixOptions(1, 40, 6, 7, 10, 6, 'down'), new MatrixOptions(1, 5, 1, 5, 1, 1, 'right')),
	[EGamesId.EUROMILLONES]: new Game(EGamesId.EUROMILLONES, 'Euromillones', 'euromillones', new GameOptions(5, 5), new MatrixOptions(1, 50, 7, 8, 10, 5, 'down'), new MatrixOptions(1, 12, 2, 7, 5, 2, 'right')),
	[EGamesId.PRIMITIVA]: new Game(EGamesId.PRIMITIVA, 'Primitiva', 'primitiva', new GameOptions(8, 8), new MatrixOptions(1, 49, 8, 7, 11, 6, 'down')),
	[EGamesId.EL_GORDO]: new Game(EGamesId.EL_GORDO, 'El Gordo', 'el-gordo', new GameOptions(6, 6), new MatrixOptions(1, 54, 8, 7, 11, 5, 'down'), new MatrixOptions(1, 10, 2, 7, 1, 1, 'right')),
	[EGamesId.LOTERIA_DE_NAVIDAD]: new Game(EGamesId.LOTERIA_DE_NAVIDAD, 'Loteria de Navidad', 'loteria-de-navidad'),
	[EGamesId.LOTERIA_NACIONAL]: new Game(EGamesId.LOTERIA_NACIONAL, 'Loteria Nacional', 'loteria-nacional'),
	[EGamesId.LOTERIA_DE_EL_NIÑO]: new Game(EGamesId.LOTERIA_DE_EL_NIÑO, 'Loteria de El Niño', 'loteria-de-el-nino'),
	[EGamesId.EL_MILLON]: new Game(EGamesId.EL_MILLON, 'El Millon', 'el-millon'),
	[EGamesId.LLUVIA_DE_MILLONES]: new Game(EGamesId.LLUVIA_DE_MILLONES, 'Lluvia de Millones', 'lluvia-de-millones'),
	[EGamesId.QUINIELA]: new Game(EGamesId.QUINIELA, 'Quiniela', 'quiniela'),
};

export function getGameBySlug(slug: string): Game | undefined {
	return Object.values(gamesMap).find(game => game.slug === slug);
}

export function getGameById(id: EGamesId): Game | undefined {
	return gamesMap[id];
}

export function getGameOptionsBySlug(slug: string): IBetOptions | undefined {
	switch (slug) {
		case 'bonono':
			const options: IBetItemOptions = {
				firstItem: 0,
				itemsPerBet: 49,
				columns: 7,
				rows: 8,
				maxItemsPerBet: 6,
				maxItemsChoosen: 11,
				orientation: true,
			};
			const extraOptions: IBetItemOptions = {
				firstItem: 0,
				itemsPerBet: 0,
				columns: 0,
				rows: 0,
				maxItemsPerBet: 0,
				maxItemsChoosen: 0,
				orientation: true,
			};

			return {
				idGame: 1,
				titleGame: 'Bonoloto',
				totalBets: 8,
				betsAtOnce: 8,
				betMode: 'manual',
				numberBetOptions: options,
				extraOptions: extraOptions,
			};
			break;
		default:
			return undefined;
	}
}

/*
	private loadOptions(): void {

		this.options = {
			idGame: 1,
			titleGame: 'Bonoloto',
			totalBets: 8,
			betMode: 'manual',
			betsAtOnce: 8,
			numberBetOptions: this.loadNumberBetOptions(),
			extraOptions: this.loadStarBetOptions()
		};

		this.betService.setOptions(this.options);

	}

	private loadNumberBetOptions(): IBetItemOptions {

		const options: IBetItemOptions = {
			firstItem: 0,
			itemsPerBet: 49,
			columns: 7,
			rows: 8,
			maxItemsPerBet: 6,
			maxItemsChoosen: 11,
			orientation: true,
		};
		return options;

	}

	private loadStarBetOptions(): IBetItemOptions {

		const options: IBetItemOptions = {
			firstItem: 0,
			itemsPerBet: 0,
			columns: 0,
			rows: 0,
			maxItemsPerBet: 0,
			maxItemsChoosen: 0,
			orientation: true,
		};
		return options;

	}
*/